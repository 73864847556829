import styled from '@emotion/styled'
import { graphql, Link, PageProps } from 'gatsby'
import React from 'react'
import SEO from '../components/atoms/seo'
import OfficialLayout from '../components/organisms/officialLayout'
import OfficialLowHeader from '../components/organisms/officialLowHeader'

const Wrapper = styled.main`
  padding: 0 0 80px;

  .p-section-inner {
    max-width: 1080px;
    margin: 0 auto;
    padding: 80px 40px 0;

    h3 {
      margin: 0 0 20px;
    }

    p {
      margin: 0 0 20px;
    }

    ul,
    ol {
      margin: 5px 0 20px;

      li {
        margin-bottom: 5px;
        text-indent: -1.15em;
        margin-left: 30px;
      }
    }

    ul {
      li {
        display: list-item;
        list-style: disc inside;

        ul {
          li {
            list-style: circle inside;
            margin-left: 30px;

            ul {
              li {
                list-style: square inside;
              }
            }
          }
        }
      }
    }

    ol {
      li {
        display: list-item;
        list-style: decimal inside;

        ol {
          li {
            margin-left: 30px;
          }
        }

        ul {
          li {
            list-style: disc inside;
          }
        }
      }

      &.parent {
        li {
          list-style-type: none;
          counter-increment: cnt;

          &:before {
            content: '(' counter(cnt) ') ';
          }
        }
      }
    }
  }
`

type IProps = PageProps<GatsbyTypes.IndexPageQuery>

/**
 * 利用規約[サービスサイト利用規約]
 * @constructor
 */
const TermsPage = ({ location }: IProps) => (
  <OfficialLayout location={location}>
    <SEO title="利用規約" />
    <Wrapper>
      <OfficialLowHeader label="利用規約" description="キンクラ" />
      {/** 第1条 */}
      <div className="p-section-inner">
        <h3>第1条（定義）</h3>
        <p>
          本利用規約（以下「本規約」といいます。）において使用する以下の用語は、各々以下に定める意味を有するものとします。
        </p>
        <ol className="parent">
          <li>
            「サービス利用契約」とは、本規約を契約条件として当社と登録ユーザーの間で締結される、本サービスの利用契約を意味します。
          </li>
          <li>
            「知的財産権」とは、著作権、特許権、実用新案権、意匠権、商標権その他の知的財産権（それらの権利を取得し、またはそれらの権利につき登録等を出願する権利を含みます。）を意味します。
          </li>
          <li>「当社」とは、CLINKS株式会社を意味します。</li>
          <li>
            「当社ウェブサイト」とは、当社が運営する本サービスに関する
            <Link
              to="https://www.clinks.jp/policy/"
              target="_blank"
              rel="noreferrer"
            >
              ウェブサイト
            </Link>
            、アプリケーション（理由の如何を問わず、当社のウェブサイトの内容が変更された場合は、当該変更後のウェブサイトを含みます。）を意味します。
          </li>
          <li>
            「登録ユーザー」とは、第3条（利用契約）に基づいて本サービスの利用契約を当社と締結している個人または法人等を意味します。
          </li>
          <li>
            「利用者」とは、本規約に基づき登録ユーザーが本サービスの利用を認めた第三者を意味します。
          </li>
          <li>「お客様」とは、登録ユーザー及び利用者の総称を意味します。</li>
          <li>
            「本サービス」とは、当社が提供するキンクラという名称の勤怠管理サービス（理由の如何を問わずサービスの名称または内容が変更された場合は、当該変更後のサービスを含みます。）を意味します。
          </li>
          <li>「ライセンス」とは、利用者として登録可能な資格を意味します。</li>
        </ol>
      </div>
      {/** 第2条 */}
      <div className="p-section-inner">
        <h3>第2条（規約の適用）</h3>
        <ol>
          <li>
            本規約は、本サービスの提供条件及び本サービスの利用に関する当社とお客様との間の権利義務関係を定めることを目的とし、お客様と当社との間の本サービスの利用に関わる一切の関係に適用されます。本規約に同意いただけない場合、本サービスを利用することはできません。
          </li>
          <li>
            当社が当社ウェブサイト上で掲載する本サービス利用に関するルールは、本規約の一部を構成するものとします。
          </li>
          <li>
            本規約の内容と、前項のルールその他の本規約外における本サービスの説明等とが異なる場合は、本規約の規定が優先して適用されるものとします。
          </li>
          <li>
            登録ユーザーは、自己が利用を認めた利用者が本サービスの利用を開始する際に、本規約の内容を説明し遵守させる責任があります。
          </li>
          <li>
            本規約の内容を理解し遵守することが出来ない利用者は本サービスを利用することはできません。
          </li>
        </ol>
      </div>
      {/** 第3条 */}
      <div className="p-section-inner">
        <h3>第3条（利用契約）</h3>
        <ol>
          <li>
            本サービスの利用を希望する者は、本規約を遵守することに同意し、かつ当社の定める一定の情報（以下「登録事項」といいます。）を当社の定める方法で当社に提供することにより、当社に対し、本サービスの利用の申し込みをすることができます。申し込みが行われ当社に到達した時点で、当社は申し込みを承諾したものとして利用登録が完了し、サービス利用契約が登録ユーザーと当社の間に成立します。
          </li>
          <li>
            前項の規定にかかわらず、書面での契約締結の場合には、本サービスの利用の申し込みがされたことに対して、当社が承諾した場合に利用登録が完了し、サービス利用契約が登録ユーザーと当社の間に成立します。
          </li>
          <li>
            お客様は、本サービスの契約締結後の申し込みの撤回、キャンセルはできません。
          </li>
          <li>
            サービス利用契約の期間は1ヶ月間または１年間とし、期間満了日の前日までに、当社の定める方法により解約を申し出ない限り、更に同様の期間更新されるものとし、以後も同様とします。
          </li>
          <li>
            お客様は、本サービスの利用契約成立日から利用期間満了末日までの間、既払利用料金の返還を請求できないものとします。
          </li>
          <li>
            未成年者の方が本サービスの利用申し込みをする場合には、事前に親権者等の法定代理人の同意を得るものとします。
          </li>
        </ol>
      </div>
      {/** 第4条 */}
      <div className="p-section-inner">
        <h3>第4条（登録事項の変更）</h3>
        <ol>
          <li>
            お客様は、登録事項に変更があった場合、当社の定める方法により当該変更事項を遅滞なく当社に通知するものとします。
          </li>
          <li>
            当社は、お客様が前項に基づいて変更の通知を行わない場合は、現在登録されている内容をお客様の情報とみなし、変更の通知が行われないことに起因した結果またはお客様に生じた損害について、当社は一切責任を負わないものとします。
          </li>
        </ol>
      </div>
      {/** 第5条 */}
      <div className="p-section-inner">
        <h3>第5条（プランの内容及び変更）</h3>
        <ol>
          <li>
            本サービスにおける各プランの具体的内容は、別途定める
            <a href="/plan-list" target="_blank">
              プラン比較ページ
            </a>
            の内容によります。
          </li>
          <li>
            第3条（利用契約）に基づく登録が完了し、当社と登録ユーザーとの間でサービス利用契約が締結された日から翌々月末までの間は、無料トライアル期間とします（ただし、登録ユーザー一人につき一回のみ）。
          </li>
          <li>
            登録ユーザーは、サービス利用契約期間中は当社の定める方法に基づき、いつでもプラン変更を申し込むことができます。プラン変更の申し込みが行われ、当社に到達した場合、当社は申し込みを承諾したものとし、申し込み到達時から変更後のプランが適用されます。
          </li>
          <li>
            本サービスは1つの法人（1社）つき１つの有効なアカウントを発行します。1社で複数のアカウントを発行し利用していた場合は、利用実績に応じお客様へ利用金額を請求します。
          </li>
        </ol>
      </div>
      {/** 第6条 */}
      <div className="p-section-inner">
        <h3>第6条（利用者）</h3>
        <ol>
          <li>
            登録ユーザーは、加入プランに応じたライセンスの数の範囲内で、複数の方を利用者として（1ライセンスにつき一人について）登録することができます。ライセンスが適用される利用者を変更（すなわち、既存の利用者の登録を削除し、別の方を利用者として登録）することはできますが、1ライセンスを同時に複数の方で利用者として登録することはできません。
          </li>
          <li>
            利用者は、登録ユーザーの契約期間内において、ユーザー登録されている間、本規約に従い本サービスを利用することができます。
          </li>
          <li>
            登録ユーザーは、加入プランに規定された人数の範囲内であれば、自由に利用者の追加、削減を申し込むことができます。
          </li>
        </ol>
      </div>
      {/** 第7条 */}
      <div className="p-section-inner">
        <h3>第7条（パスワード及びユーザーID等の管理）</h3>
        <ol>
          <li>
            お客様は、自己の責任において、本サービスに関するメールアドレス、パスワード及びユーザーID等を適切に管理及び保管するものとし、これを第三者に利用させ、または貸与、譲渡、名義変更、売買等をしてはならないものとします。
          </li>
          <li>
            メールアドレス、パスワードまたはユーザーID等の管理不十分、使用上の過誤、第三者の使用等によって生じた損害に関する責任はお客様が負うものとします。
          </li>
        </ol>
      </div>
      {/** 第8条 */}
      <div className="p-section-inner">
        <h3>第8条（料金及び支払方法等）</h3>
        <ol>
          <li>
            本サービスの利用料金は、別途定める
            <Link to="/plan-list/" target="_blank">
              プラン比較ページ
            </Link>
            の内容によります。
          </li>
          <li>
            支払方法は、Freeプラン及びBusinessプランはクレジットカード決済のみ、Enterpriseプランはクレジットカード決済または当社が別途定める方法をご利用できます。
          </li>
          <li>
            利用料金は、当社が別途定める期日までに前払いで支払うものとします。
          </li>
          <li>
            経費精算オプション、SES案件管理オプションの基本料金は、料金プラン毎に定めるものとし、別途定める期日までに後払いで支払うものとします。
          </li>
          <li>
            経費精算オプション、SES案件管理オプションの追加料金は、月末時点で料金プラン毎に定める利用数を超えた場合、次回のお支払い期日に、プラン毎に定める追加料金を請求します。
          </li>
          <li>
            当社指定の支払方法にて利用料金の支払いが確認できなかった場合、当社指定の方法において別途お支払期日、本サービスの利用を停止する期日及び再開方法が記載された通知をします。
          </li>
          <li>
            前項の規定に基づく通知があったにもかかわらず、通知されたお支払期日までにお支払いが完了しない場合には、本サービスの利用を停止いたします。ただし、その後支払完了が確認されましたら、再び本サービスの利用が可能となります。
          </li>
          <li>
            登録ユーザーが利用料金の支払を遅滞した場合、年14.6％の割合による遅延損害金を当社に支払うものとします。
          </li>
          <li>
            利用料金は、月初の利用人数に応じて変更されるものとし、月途中の利用者数の削減があった場合でも、利用料金の払い戻しは一切行われません。ただし、月途中の利用者数の追加があった場合には、利用料金は利用者数変更日を含めて日割精算され、次回のお支払い期日において加算請求いたします。
          </li>
          <li>
            本サービスを月途中で上位プランへ変更する場合、プラン内容は即時適用されます。プラン変更による利用料金の差額はプラン変更日を含めて日割計算され、次回のお支払い期日において加算請求いたします。下位プランへの変更は月途中から適用されず、機能制限と利用料金は翌月から適用されます。
          </li>
          <li>
            無料トライアル期間終了後にFreeプランへのプラン変更はできません。
          </li>
          <li>
            当社は、登録ユーザーの承諾なく利用料金額を改定または部分的変更を行うことができるものとし、登録ユーザーは、改定後の利用料金を当社指定の方法で支払うものとします。なお、改定後の料金体系は、次回到来する契約更新時より適用されるものとします。
          </li>
        </ol>
      </div>
      {/** 第9条 */}
      <div className="p-section-inner">
        <h3>第9条（権利帰属）</h3>
        <p>
          本サービスに関する知的財産権は全て当社または当社にライセンスを許諾している者に帰属しており、本規約に基づく本サービスの利用許諾は、本サービスに関する当社または当社にライセンスを許諾している者の知的財産権の使用許諾を意味するものではありません。
        </p>
      </div>
      {/** 第10条 */}
      <div className="p-section-inner">
        <h3>第10条（禁止事項）</h3>
        <p>
          お客様は、本サービスの利用にあたり、以下の各号のいずれかに該当する行為または該当すると当社が判断する行為をしてはなりません。
        </p>
        <ol className="parent">
          <li>法令に違反する行為または犯罪行為に関連する行為</li>
          <li>
            当社、本サービスの他のお客様またはその他の第三者に対する詐欺または脅迫行為
          </li>
          <li>公序良俗に反する行為</li>
          <li>
            当社、本サービスの他のお客様またはその他の第三者の知的財産権、肖像権、プライバシの権利、名誉、その他の権利または利益を侵害する行為
          </li>
          <li>料金を操作する行為</li>
          <li>過度なプラン変更、利用者の入退社を繰り返す行為</li>
          <li>
            本サービスのネットワークまたはシステム等に過度な負荷をかける行為
          </li>
          <li>
            当社が提供するソフトウェアその他のシステムに対するリバースエンジニアリング、逆コンパイル、または逆アセンブラ、その他の解析行為
          </li>
          <li>本サービスの運営を妨害するおそれのある行為</li>
          <li>
            本サービスを通じて、または本サービスに関連してコンピュータウィルス等、有害なプログラムを使用、または提供する行為、またはそのおそれのある行為及び当社のネットワークまたはシステム等への不正アクセス
          </li>
          <li> 第三者に成りすます行為</li>
          <li>
            本サービスの他のお客様のIDまたはメールアドレス及びパスワードを利用する行為
          </li>
          <li> 1つの法人（1社）で複数のアカウントを作成し、利用する行為</li>
          <li>
            当社が事前に許諾しない本サービス上での宣伝、広告、勧誘、または営業行為
          </li>
          <li>本サービスの他のお客様の情報の収集</li>
          <li>
            当社、本サービスの他のお客様またはその他の第三者に不利益、損害、不快感を与える行為
          </li>
          <li>
            当社ウェブサイト上で掲載する本サービス利用に関するルールに抵触する行為
          </li>
          <li>
            ハードウェアまたはソフトウェアの管理を無効にする等、ハードウェアまたはソフトウェアのメーカーが定める利用規約、ガイドライン、その他の規定類で定められた使用条件に違反して改造されたデバイスを使って、本サービスを利用する行為
          </li>
          <li>
            反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味します。以下同じ。）への利益供与。また、反社会的勢力等の維持、運営もしくは経営に協力もしくは関与する等反社会的勢力等との何らかの交流もしくは関与をする行為
          </li>
          <li>当社に対して虚偽の申告を行う行為</li>
          <li>
            当社の事前の書面による同意なく第三者へ本サービス利用契約上の地位を貸与、譲渡する行為
          </li>
          <li>前各号の行為を直接または間接に惹起し、または容易にする行為</li>
          <li>前各号の行為を試みること</li>
          <li>その他、当社が不適切と判断する行為</li>
        </ol>
      </div>
      {/** 第11条 */}
      <div className="p-section-inner">
        <h3>第11条（本サービスの停止等）</h3>
        <p>
          当社は、以下のいずれかに該当する場合には、お客様に事前に通知することなく、本サービスの全部または一部の提供を停止または中断することができるものとします。
        </p>
        <ol className="parent">
          <li>
            本サービスに係るコンピューター・システムの点検または保守作業を緊急に行う場合
          </li>
          <li>
            コンピューター、通信回線等の障害、誤操作、過度なアクセスの集中、不正アクセス、ハッキング等により本サービスの運営ができなくなった場合
          </li>
          <li>
            地震、落雷、火災、風水害、停電、天災地変などの不可抗力により本サービスの運営ができなくなった場合
          </li>
          <li>その他、当社が停止または中断を必要と判断した場合</li>
        </ol>
      </div>
      {/** 第12条 */}
      <div className="p-section-inner">
        <h3>第12条（当社による解除等）</h3>
        <ol>
          <li>
            当社は、お客様が、以下の各号のいずれかの事由に該当する場合は、事前に通知または催告することなく、また、帰責性の有無にかかわらず、本サービスの利用を一時的に停止し、またはサービス利用契約を解除することができます。
            <ol className="parent">
              <li>本規約のいずれかの条項に違反した場合</li>
              <li>
                重要な財産に対する差押、仮差押、仮処分、租税延滞処分その他公権力の処分を受けた場合{' '}
              </li>
              <li>
                支払停止もしくは支払不能となり、または破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始もしくはこれらに類する手続の開始の申立てがあった場合
              </li>
              <li>
                解散、事業の全部もしくは重要な一部の譲渡、合併、分割がなされた場合、またはそれらの決議がなされた場合
              </li>
              <li>
                監督官庁から営業停止、営業免許、営業登録の取消しその他の公権力の処分がなされた場合
              </li>
              <li>その他財産状況が悪化した場合、またはそのおそれがある場合</li>
              <li>本サービスを1ヶ月以上利用していないと当社が判断した場合</li>
              <li>
                当社からの問い合わせその他の回答を求める連絡に対して1ヶ月以上応答がない場合
              </li>
              <li>
                当社に提供した登録事項の全部または一部につき虚偽、誤記または記載漏れがあった場合
              </li>
              <li>
                未成年者、成年被後見人、被保佐人または被補助人のいずれかであり、法定代理人、後見人、保佐人または補助人の同意等を得ていなかった場合
              </li>
              <li>
                反社会的勢力等である、または資金提供その他を通じて反社会的勢力等の維持、運営もしくは経営に協力もしくは関与する等反社会的勢力等との何らかの交流もしくは関与を行っていると当社が判断した場合
              </li>
              <li>
                過去当社との契約に違反した者またはその関係者であると当社が判断した場合
              </li>
              <li>過去に本条に定める措置を受けたことがある場合</li>
              <li>その他、サービス利用契約を継続しがたい事由がある場合</li>
            </ol>
          </li>
          <li>
            前項各号のいずれかの事由に該当した場合、登録ユーザーは、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。また、前項の規定によってサービス利用契約が解除された場合、当社は、既に当社に支払われた利用料金の払い戻しは行わず、解除によって生じたお客様の損害等について一切責任を負いません。
          </li>
        </ol>
      </div>
      {/** 第13条 */}
      <div className="p-section-inner">
        <h3>第13条（登録ユーザーによる解約）</h3>
        <ol>
          <li>
            登録ユーザーは、当社所定の方法で解約を申し出ることにより、いつでもサービス利用契約を解約することができます。解約の申し込みが行われ当社に到達した場合、次に到来する契約更新日をもって解約が完了し、当該期日が解約日となります。
          </li>
          <li>
            解約にあたり、当社に対して負っている債務が有る場合は、登録ユーザーは、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。また、既にお支払い頂いた利用料金がある場合でも、当社から登録ユーザーに対して払い戻しは行われません。
          </li>
          <li>
            解約後のお客様情報の取扱いについては、第19条及び第20条の規定に従うものとします。
          </li>
        </ol>
      </div>
      {/** 第14条 */}
      <div className="p-section-inner">
        <h3>第14条（本サービスの内容の変更・中断及び終了）</h3>
        <ol>
          <li>
            当社は、当社の都合により、お客様に事前に通知することなく、本サービスの内容を変更することができます。
          </li>
          <li>
            当社は、当社の都合により、本サービスの提供を中断又は終了することができます。当社が本サービスの提供を終了する場合はお客様に事前に通知するものとしますが、緊急その他やむを得ない事情がある場合はこの限りでありません。
            <ol className="parent">
              <li>
                本サービスの運営・提供に必要なアップデートや設備等の保守を定期的又は緊急に行う場合
              </li>
              <li>
                システムや通信回線の障害、火災、停電、不慮の事故又は不可抗力によるサービス提供が継続できなくなった場合
              </li>
              <li>
                天災地変（地震、噴火、洪水、津波）や戦争、暴動、騒乱、労働争議などの非常事態の場合
              </li>
              <li>運営上又は技術上の理由、その他やむを得ない理由による場合</li>
            </ol>
          </li>
        </ol>
      </div>
      {/** 第15条 */}
      <div className="p-section-inner">
        <h3>第15条（保証の否認）</h3>
        <ol>
          <li>
            当社は、本サービスがお客様の特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性を有すること、入力された情報の正確性や集計結果が勤務実態を正確に反映していること、本サービスの利用がお客様に適用のある法令または業界団体の内部規則等に適合すること、継続的に利用できること、及び不具合が生じないこと、本サービスから得られる結果や情報が正確で信頼できるものであることについて、明示または黙示を問わず何ら保証するものではありません。
          </li>
          <li>
            当社は、お客様が本サービスを利用して行うデータ入力やアップロードされるファイルについて、一切監視の責任を負いません。
          </li>
          <li>
            お客様は、自らの責任と費用において、ハードウェア、ソフトウェア、インターネット接続回線、セキュリティの確保等、本サービスの利用に必要な環境を整備するものとし、当社は、本サービス利用中のお客様が使用する機器等が正常に作動すること等を何ら保証するものではありません。
          </li>
        </ol>
      </div>
      {/** 第16条 */}
      <div className="p-section-inner">
        <h3>第16条（免責）</h3>
        <ol>
          <li>
            当社は、本サービスの利用により発生したお客様の損害については、一切の賠償責任を負いません。
          </li>
          <li>
            当社は、本サービスに発生した不具合、エラー、障害等により本サービスが利用できないことによって引き起こされた損害について一切の損害賠償を負いません。
          </li>
          <li>
            お客様のユーザーＩＤ、メールアドレス及びパスワード等が第三者によって使用されていた場合、お客様が被った損害について、お客様の故意や過失の有無にかかわらず、当社は一切責任を負いません。
          </li>
          <li>
            第三者によるクレジットカード不正利用が行われた場合、お客様と、第三者及びクレジットカード会社との間で処理解決するものとし、お客様の故意や過失の有無にかかわらず、当社は一切責任を負いません。
          </li>
          <li>
            お客様とクレジットカード会社、収納代行会社、その他金融機関などの間で紛争が発生した場合は、当該当事者双方で解決するものとし、当社は一切責任を負いません。
          </li>
          <li>
            前各項に規定する場合のほか、本サービスに関連してお客様と他のお客様または第三者との間において生じた取引、連絡、紛争等については、お客様が自己の責任によって解決するものとし、当社は一切の責任を負わないものとします。
          </li>
          <li>
            第11条（本サービスの停止等）、第12条（当社による解除等）及び第14条（本サービスの内容の変更及び終了）の規定による本サービスの変更、停止及び終了等によって、お客様が本サービスの全部または一部を利用できなくなったことに関する損害、作業が中断したことに関する損害、データが失われたことに関する損害、本サービスを利用することによって得られたであろう利益を得られなかった損害など、本サービスの利用に際して発生した損害については、直接損害・間接損害、現実に発生した損害か否かを問わず、当社は一切の責任を負わないものとします。
          </li>
          <li>
            本サービスを通じて登録、提供される情報等の流出もしくは消失、漏洩等その他本サービスの利用に関連してお客様に損害が生じたとしても、当社は一切の責任を負わないものとします。
          </li>
          <li>
            当社は、本サービスの不具合その他を補修する義務および本サービスを改良又は改善する義務は負いません。ただし、お客様に本サービスのアップデート版又はバージョンアップ情報等を提供する場合があります。この場合、かかるアップデート版又はバージョンアップ情報等も本サービスとして扱い、これらにも本利用規約が当然に適用されます。
          </li>
          <li>
            本規約における当社の各免責規定は、当社に故意または重過失が存在する場合には適用しません。
          </li>
        </ol>
      </div>
      {/** 第17条 */}
      <div className="p-section-inner">
        <h3>第17条（責任の制限）</h3>
        <p>
          当社が損害賠償責任を負う場合、当社は、債務不履行、不法行為その他の請求の原因の如何を問わず、本サービスに関してお客様が被った損害につき、過去1ヶ月間に登録ユーザーが当社に支払った対価の金額を超えて賠償する責任を負わないものとし、また、付随的損害、間接損害、特別損害、将来の損害及び逸失利益にかかる損害については、賠償する責任を負わないものとします。ただし、かかる賠償額の上限について、当社の故意または重過失による場合はこの限りではありません。
        </p>
      </div>
      {/** 第18条 */}
      <div className="p-section-inner">
        <h3>第18条（秘密保持）</h3>
        <p>
          お客様は、本サービスに関連して当社がお客様に対して秘密に取扱うことを求めて開示した非公知の情報について、当社の事前の書面による承諾がある場合を除き、秘密に取扱うものとします。
        </p>
      </div>
      {/** 第19条 */}
      <div className="p-section-inner">
        <h3>第19条（お客様情報の取扱い）</h3>
        <p>
          当社によるお客様情報の取扱いについては、別途当社
          <Link to="/policy/" target="_blank">
            プライバシーポリシー
          </Link>
          の定めによるものとし、お客様はこのプライバシーポリシーに従って当社がお客様情報を取扱うことについて同意するものとします。
        </p>
      </div>
      {/** 第20条 */}
      <div className="p-section-inner">
        <h3>第20条（データの閲覧・利用・開示・削除に関する合意事項）</h3>
        <ol>
          <li>
            当社は、お客様が登録時に入力した情報や勤怠情報、及びファイル、ログなどお客様が送信した情報（以下「送信情報」といいます。）について、細心の注意を払い、安全に管理するよう努めます。
          </li>
          <li>
            当社は、お客様、利用者に関するデータを個人、企業を特定しない形で集計し統計情報として用い、本サービスの改善目的で利用するできるものとします。
          </li>
          <li>
            お客様は、送信情報に係る著作権を、送信後も引き続き保有します。当社は、お客様に対する本サービスの提供のために必要な範囲に限って、送信情報を複製、翻案、自動公衆送信及びそのために必要な送信可能化その他の利用を行うことができるものとします。
          </li>
          <li>
            第1項にかかわらず、本サービスが本質的に情報の喪失、改変、破壊等の危険が内在するインターネット通信網を介したサービスであることに鑑みて、お客様は、送信情報を自らの責任においてバックアップするものとします。当該バックアップを怠ったことによってお客様が被った損害について、当社は、送信情報の復旧を含めて、一切責任を負いません。
          </li>
          <li>
            当社は、送信情報の開示及び削除されたデータの復旧対応は行っておらず、また、送信情報の開示及び復旧について、一切責任を負いません。
          </li>
          <li>
            当社は、以下各号の一に該当する場合には、送信情報を閲覧・利用し、または第三者へ開示することがあります。
            <ol className="parent">
              <li>
                捜査機関の令状があるとき、裁判所からの調査嘱託等開示の要求があるとき、行政機関から開示要求があるとき
              </li>
              <li>法律に従い開示の義務を負うとき</li>
              <li>
                当社が、お客様が第10条（禁止事項）に定める禁止事項に該当する行為を行っていると判断したとき
              </li>
              <li>
                お客様や第三者の生命・身体・その他重要な権利を保護するために必要なとき
              </li>
              <li>本サービスのメンテナンスのため緊急の必要があるとき</li>
              <li>上記各号に準じる必要性があるとき</li>
            </ol>
          </li>
          <li>
            当社は、以下各号の一に該当する場合には、送信情報について、その一部または全部を削除することがあります。当社は、削除された送信情報について、当該情報の復旧を含めて一切責任を負いません。
            <ol className="parent">
              <li>登録ユーザーの同意を得たとき</li>
              <li>
                当社が、お客様が第10条（禁止事項）各号に該当する禁止行為を行っていると判断したとき
              </li>
              <li>
                当該利用契約が、第12条（当社による解除等）に基づき終了したとき
              </li>
              <li>
                当該利用契約が、第13条（登録ユーザーによる解約）に基づき終了したとき
              </li>
              <li>
                第14条（本サービスの内容の変更及び終了）に基づき本サービスが終了されたとき
              </li>
              <li>上記各号に準じる必要性があるとき</li>
            </ol>
          </li>
          <li>
            第4項及び第5項の定めは、当社が一定の場合に同項に定める措置を実施することを、当社に義務づけるものではありません。上記各措置の実施の有無は、当社の任意の判断によるものとします。
          </li>
          <li>
            当社はお客様から同意を得た場合（通信の受信者から通報があった場合を含む）、必要な範囲内において、お客様の通信の秘密にかかわる情報を閲覧、変更、削除又は提供することができます。
          </li>
        </ol>
      </div>
      {/** 第21条 */}
      <div className="p-section-inner">
        <h3>第21条（本規約等の変更）</h3>
        <p>
          当社は、当社が必要と認めた場合は、本規約を変更できるものとします。本規約を変更する場合、変更後の本規約の施行時期及び内容を当社ウェブサイト上での掲示その他の適切な方法により周知し、またはお客様に通知します。但し、法令上お客様の同意が必要となるような内容の変更の場合は、当社所定の方法でお客様の同意を得るものとします。
        </p>
      </div>
      {/** 第22条 */}
      <div className="p-section-inner">
        <h3>第22条（連絡／通知）</h3>
        <ol>
          <li>
            本サービスに関する問い合わせその他お客様から当社に対する連絡または通知、及び本規約の変更に関する通知その他当社からお客様に対する連絡または通知は、当社の定める方法で行うものとします。
          </li>
          <li>
            当社からの連絡または通知が、メールの送信または当社ウェブサイトへの掲載によって行われる場合は、インターネット上に配信された時点でお客様に到達したものとします。
          </li>
        </ol>
      </div>
      {/** 第23条 */}
      <div className="p-section-inner">
        {/* <h3>第23 条（広告）</h3> */}
        <h3>第24 条（広告）</h3>
        <p>
          当社は、当社の判断により本サービスに当社又は第三者の広告を掲載することができるものとします。
          この場合、お客様は当該外部ウェブサイトおよびリソースを自己の責任で利用するものとし、当社は、当
          該ウェブサイトおよびリソースの適法性・正確性・有用性・信頼性等について、一切責任を負いません。
        </p>
      </div>
      {/** 第24条 */}
      <div className="p-section-inner">
        {/* <h3>第24条（利用可能地域）</h3> */}
        <h3>第25条（利用可能地域）</h3>
        <p>本サービスの利用可能地域は、日本国内限定とします。</p>
      </div>
      {/** 第25条 */}
      <div className="p-section-inner">
        {/* <h3>第25条（サービス利用契約上の地位の譲渡等）</h3> */}
        <h3>第26条（サービス利用契約上の地位の譲渡等）</h3>
        <ol>
          <li>
            お客様は、当社の書面による事前の承諾なく、利用契約上の地位または本規約に基づく権利もしくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。
          </li>
          <li>
            当社は、お客様に対する債権を第三者に譲渡できるものとし、お客様は、かかる債権譲渡及びそのためにお客様の個人情報等が当該第三者に提供されることを予め承諾するものとします。
          </li>
          <li>
            当社は本サービスにかかる事業を他社に譲渡した場合には、当該事業譲渡に伴い利用契約上の地位、本規約に基づく権利及び義務並びにお客様の登録事項その他の顧客情報を当該事業譲渡の譲受人に譲渡することができるものとし、お客様は、かかる譲渡につき本項において予め同意したものとします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含むものとします。
          </li>
        </ol>
      </div>
      {/** 第26条 */}
      <div className="p-section-inner">
        {/* <h3>第26条（分離可能性）</h3> */}
        <h3>第27条（分離可能性）</h3>
        <p>
          本規約のいずれかの条項またはその一部が、法令等により無効または執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効または執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。
        </p>
      </div>
      {/** 第27条 */}
      <div className="p-section-inner">
        {/* <h3>第27条（準拠法及び管轄裁判所）</h3> */}
        <h3>第28条（準拠法及び管轄裁判所）</h3>
        <ol>
          <li>本規約及びサービス利用契約の準拠法は日本法とします。</li>
          <li>
            本規約またはサービス利用契約に起因し、または関連する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
          </li>
        </ol>
      </div>
      {/** 第28条 */}
      <div className="p-section-inner">
        {/* <h3>第28条（協議）</h3> */}
        <h3>第29条（協議）</h3>
        <p>
          本サービスに関してお客様と当社との間で問題が生じた場合、お客様と当社は誠意をもって協議し、その解決に努めるものとします。
        </p>
      </div>
      {/** 制定日 */}
      <div className="p-section-inner">
        <p>
          2023年12月7日 改定
          <br />
          2023年11月16日 改定
          <br />
          2022年11月24日 改定
          <br />
          2021年10月27日 制定
        </p>
      </div>
    </Wrapper>
  </OfficialLayout>
)

export default TermsPage

export const pageQuery = graphql`
  query TermsPage {
    site {
      siteMetadata {
        title
      }
    }
  }
`
